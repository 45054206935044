import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Button, Popover, Space, Spin, Typography } from 'antd';

import { CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import useScrapedData from 'hooks/useScrapedData';
import StepsContext from 'pages/Configuration/context';
import './styles.scss';

const SCRAPE_STATUS = {
    SUCCESS: 'success',
    CANCELED: 'canceled',
    SCRAPING: 'scraping',
};

const Buttons = ({ status, handleRefresh, stopScraping, launch }) => {
    const {
        setCurrent
    } = useContext(StepsContext);

    const handleModifyTags = useCallback(() => {
        stopScraping(false);
        setCurrent(1);
    }, [setCurrent, stopScraping]);

    const handleModifyConfiguration = useCallback(() => {
        stopScraping(false);
        setCurrent(0);
    }, [setCurrent, stopScraping]);

    const handleCancel = useCallback(() => {
        stopScraping(false);
    }, [stopScraping]);

    return (
        <Space>
            {SCRAPE_STATUS.SCRAPING === status
                ? (
                    <>
                        <Button onClick={handleCancel}>
                            {'Cancel'}
                        </Button>
                        <Button type='primary' onClick={handleRefresh}>
                            {'Get scraped contents'}
                        </Button>
                    </>
                )
                : (
                    <>
                        <Button onClick={launch}>
                            {'Relaunch'}
                        </Button>
                        <Button type='primary' onClick={handleModifyTags}>
                            {'Change Values'}
                        </Button>
                        <Button type='primary' onClick={handleModifyConfiguration}>
                            {'Modify Configuration'}
                        </Button>
                    </>
                )
            }
        </Space>
    );
};

const Content = ({ status, scrapedData, hasManuallyRefreshed, isLoading, handleRefresh }) => {
    const [isCopiedVisible, setIsCopiedVisible] = useState(false);

    const handleCopyScrapedData = useCallback(() => {
        navigator.clipboard.writeText(JSON.stringify(scrapedData, null, 2));
        setIsCopiedVisible(true);

        setTimeout(() => {
            setIsCopiedVisible(false);
        }, 2000);
    }, [scrapedData]);

    const message = useMemo(() => {
        switch (status) {
            case SCRAPE_STATUS.SCRAPING:
                return hasManuallyRefreshed && !isLoading
                    ? 'No data has been found. Have you clicked finish or cancel on the extension?'
                    : 'The scraped contents will be displayed once the extesion has finished scraping or you can click the button bellow to see them now.';

            case SCRAPE_STATUS.CANCELED:
                return 'The scraping has been cancelled, do you want to modify the configuration?';

            default:
                return null;
        }
    } , [status, hasManuallyRefreshed, isLoading]);

    return SCRAPE_STATUS.SUCCESS === status
        ? (
            <pre>
                <div className='buttons'>
                    <Popover
                        content="Copied!"
                        trigger="click"
                        visible={isCopiedVisible}
                    >
                        <Button
                            icon={<CopyOutlined />}
                            onClick={handleCopyScrapedData}
                            type="primary"
                            size={'small'}
                            shape="circle"
                        />
                    </Popover>
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={handleRefresh}
                        type="primary"
                        size={'small'}
                        shape="circle"
                    />
                </div>
                <code>
                    {JSON.stringify(scrapedData, null, 2)}
                </code>
            </pre>
        )
        : (
            <Typography.Text strong>
                {message}
            </Typography.Text>
        );
};

const Overlay = ({ isScraping, setIsScraping, launch, token }) => {
    const [hasManuallyRefreshed, setHasManuallyRefreshed] = useState(false);

    const {
        data = {},
        isFetching: isLoading,
        refetch
    } = useScrapedData(token);

    const { scrapedData, scrapedCreatedAt } = data;

    const handleRefresh = useCallback(() => {
        setHasManuallyRefreshed(true);
        refetch();
    } , [refetch]);

    const status = useMemo(() => {
        switch (true) {
            case !!scrapedCreatedAt && !!scrapedData:
                return SCRAPE_STATUS.SUCCESS;

            case !!scrapedCreatedAt && !scrapedData:
                return SCRAPE_STATUS.CANCELED;

            default:
                return SCRAPE_STATUS.SCRAPING;
        }
    }, [scrapedCreatedAt, scrapedData]);


    const stopScraping = useCallback(() => {
        setIsScraping(false);
        setHasManuallyRefreshed(false);
    } , [setIsScraping]);

    const styles = classNames(
        'scraping-overlay',
        {
            '-scraping': isScraping,
            '-loading': hasManuallyRefreshed && isLoading
        }
    );

    return (
        <div className={styles}>
            <Spin spinning={hasManuallyRefreshed && isLoading} />
            <div className='main'>
                <div className='title'>
                    <Typography.Title level={3}>
                        {'The extension is scraping'}
                    </Typography.Title>
                </div>
                <div className='token'>
                    <Typography.Text strong>
                        {'Token'}
                    </Typography.Text>
                    <Typography.Text>
                        {token}
                    </Typography.Text>
                </div>
                <div className='contents'>
                    <Content
                        status={status}
                        scrapedData={scrapedData}
                        hasManuallyRefreshed={hasManuallyRefreshed}
                        isLoading={isLoading}
                        handleRefresh={handleRefresh}
                    />
                </div>
                <div className='buttons'>
                    <Buttons
                        status={status}
                        handleRefresh={handleRefresh}
                        stopScraping={stopScraping}
                        launch={launch}
                    />
                </div>
            </div>
        </div>
    );
};

export default Overlay;
