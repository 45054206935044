import { useMutation } from '@tanstack/react-query';
import api from 'services/api';

import useToken from './useToken';

const usePostConfiguration = () => {
    const { data: token } = useToken();

    const { mutate, ...functions } = useMutation({
        mutationFn: api.postConfiguration(token),
    });

    return {
        postConfiguration: mutate,
        ...functions
    };
};

export default usePostConfiguration;
